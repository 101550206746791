@import '../../styles/variables.less';
.resource-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  .item-li {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 95%;
    background: #fff;
    height: 160px;
    border-bottom: 1px solid @c-borderColor;
    box-shadow: 0px 10px 40px 3px rgba(15, 17, 31, 0.05);
    img, .img-box {
      width: 250px;
      height: 50px;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        color: #41434d;
        font-size: 23px;
        &:last-of-type {
          color: #a8a9b3;
          font-size: 18px;
          font-weight: bold;
          display: inline-block;
          margin-top: 14px;
        }
      }
      &.price {
        margin-right: 0px;
        span {
          color: #41434c;
          font-size: 36px;
          margin-top: 0px;
        }
        .u-btn {
          display: none;
          height: 56px;
          width: 180px;
          box-shadow: 0 0 5px #e5e5e5;
          color: #fff;
          font-size: 24px;
          line-height: 56px;
          text-align: center;
          background: linear-gradient(90deg, #ff8417 0%, #ff9536 100%);
          border-radius: 28px;
          .f-btn(rgba(255, 255, 255, 0.3));
          position: relative;
        }
      }
      &.price-mobile {
        display: none;
      }
    }
    .config {
      display: flex;
      flex-direction: row;
      width: 50%;
      justify-content: space-between;
    }
    &.active {
      background: linear-gradient(-90deg, #effdff 0%, #f2f6ff 100%);
      box-shadow: 0px 10px 40px 3px rgba(0, 6, 48, 0.07);
      width: 100%;
      // padding: 0 2.5%;
      // height: 200px;
      box-sizing: border-box;
      div {
        span {
          font-weight: bold;
          // color: #223efb;
          // font-size: 23px;
          &:last-of-type {
            color: #a8a9b3;
            //   font-size: 18px;
            font-weight: bold;
            display: inline-block;
            //   margin-top: 14px;
          }
        }
        &.price,
        & > .price-mobile {
          span {
            color: #223efb;
            //   font-size: 50px;
            font-weight: bold;
            margin-top: 0px;
          }
          .u-btn {
            display: block;
          }
        }
      }
    }
    & > .u-btn {
      display: none;
    }
  }
}
@media screen and (max-width: 1440px) {
  .resource-list {
    .item-li {
      height: 160px * @media1024;
      img, .img-box {
        width: 250px * @media1024;
        height: 50px * @media1024;
      }
      div {
        span {
          font-size: 23px * @media1024;
          &:last-of-type {
            font-size: 18px * @media1024;
            margin-top: 14px * @media1024;
          }
        }
        &.price {
          span {
            font-size: 36px * @media1024;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .resource-list {
    .item-li {
      flex-direction: column;
      height: auto;
      padding: 40rem / @media768;
      & > div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .config {
        margin-top: 40rem / @media768;
        width: 100%;
      }
      img, .img-box {
        width: 250rem / @media768;
        height: 50rem / @media768;
      }
      div {
        span {
          font-size: 24rem / @media768;
          &:last-of-type {
            font-size: 20rem / @media768;
            margin-top: 14rem / @media768;
          }
        }
        &.price {
          display: none;
        }
        &.price-mobile {
          display: block;
          span {
            font-size: 42rem / @media768;
            color: #41434c;
          }
        }
      }
      &.active {
        height: auto !important;
        .u-btn {
          display: block;
          margin: 20rem / @media768 0;
          width: 100%;
          height: 80rem / @media768;
          font-size: 30rem / @media768;
          line-height: 80rem / @media768;
          box-shadow: 0 0 5px #e5e5e5;
          color: #fff;
          text-align: center;
          background: linear-gradient(90deg, #ff8417 0%, #ff9536 100%);
          border-radius: 28px;
          .f-btn(rgba(255, 255, 255, 0.3));
          position: relative;
        }
      }
    }
  }
}
