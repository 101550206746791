@import '../../styles/variables.less';

.m-index-title {
  padding: 80px 0;
  font-size: 72px;
  text-align: center;

  span {
    font-size: 24px;
    font-weight: 100;
  }
}

.m-index-nodeIntroduction {
  background: url('../../static/images/index-nodeIntroc-bg.jpg') no-repeat;
  background-position: left bottom;
  background-size: 100% 100%;
  height: 640px;
  position: relative;

  &>.animate {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;

    img,
    .img-box {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &>.animateMobile {
    display: none;
  }

  .nodeIntroduction-list {
    display: flex;
    justify-content: space-evenly;

    .item-li {
      width: 260px;
      // height: 260px;
      padding: 35px 2px 35px 2px;
      border: 1px solid @c-borderColor;
      background-color: @c-whiteColor;
      box-shadow: 0px 10px 28px 2px rgba(133, 160, 193, 0.1);

      .u-img {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        margin-bottom: 5px;

        img,
        .img-box {
          height: 80px;
          width: 80px;
          .transition(0.3s all);
        }
      }

      .u-text {
        text-align: center;

        b {
          display: block;
          font-size: 36px;
          color: #0048ff;
          line-height: 160%;

          span {
            font-size: 24px;
            font-weight: 100;
          }
        }

        p {
          font-size: 16px;
          font-weight: bold;
          line-height: 160%;
        }
      }

      &:hover {

        .u-img img,
        .img-box {
          margin-bottom: 30px;
        }
      }
    }
  }
}

.m-index-node {
  background: #fff;
  box-shadow: 0px 0px 0px 60px #f4fdff;

  .node-btn {
    width: 100%;
    text-align: center;
    margin-top: 60px;

    .u-btn {
      position: relative;
      display: inline-block;
      width: 400px;
      height: 100px;
      background: #0f7aff;
      border-radius: 10px;
      color: #fff;
      font-size: 36px;
      line-height: 100px;
      text-align: center;

      &::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 32px;
        height: 22px;
        margin-left: 15px;
        background: url(../../static/images/icon-rightArrow-s.png) no-repeat right center;
        background-size: cover;
      }

      &:hover::after {
        animation: nowArrow 1s infinite;
      }
    }
  }
}

@keyframes nowArrow {
  from {
    background-position-x: -32px;
  }

  to {
    background-position-x: 32px;
  }
}

.m-index-advantages {
  position: relative;

  &>.animate {
    width: 100%;
    position: absolute;
    top: 0px;
    right: 0px;

    img,
    .img-box {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &>.animateBottom {
    width: 100%;
    position: absolute;
    bottom: 20px;
    left: 0px;

    img,
    .img-box {
      width: auto;
      height: 190px;
      object-fit: contain;
    }
  }

  &>.animateMobile {
    display: none;
  }

  .advantages-list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .adm-swiper {
      --height: 564px;
      padding-bottom: 0px;

      .advantages-item {
        width: 810px;
        height: 564px;
        display: flex;
        align-items: center;

        img,
        .img-box {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
    }


    .ant-tabs {
      overflow: initial;

      .ant-tabs-nav-list {

        .ant-tabs-tab-btn>div {
          display: flex;
          align-items: center;
        }
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }
}

.m-index-resources {
  position: relative;

  &>.animate {
    width: 100%;
    position: absolute;
    bottom: 0px;
    right: 0px;

    img,
    .img-box {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .m-index-title {
    span {
      font-size: 72px;
    }
  }

  // background: url('../../static/images/index-resource-bg.jpg') no-repeat;
  // background-size: contain;
  // background-position: right 10000px;
  // .transition(0.5s all);
  // &.animate {
  //   background-position: right bottom;
  // }
  .resource-list {
    width: 960px;
    padding-bottom: 30px;

    li {
      width: 100%;
    }
  }
}

.m-index-content {
  background: url('../../static/images/index-content-bg1.jpg') no-repeat;
  background-size: contain;
  background-position: left top;

  &>.animateMobile {
    display: none;
  }

  .content-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 550px;

    .left {
      width: 48%;
      height: 100%;
      background: #fff;
      box-shadow: 0px 10px 28px 2px rgba(133, 160, 193, 0.1);
      position: relative;

      &:hover {

        img,
        .img-box {
          transform: scale(1.08);
        }
      }

      .img,
      .img-box {
        width: 100%;
        height: auto;
        overflow: hidden;
        padding: 0;
      }

      img,
      .img-box {
        width: 100%;
        height: 100%;
        transform: scale(1.0);
        .transition(0.3s all);

        &:hover {
          transform: scale(1.08);
        }
      }

      .img-text {
        display: none;
        position: absolute;
        width: 100%;
        height: 378px;
        // display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0px;
        top: 0px;
        padding-left: 60px;
        text-align: center;

        p {
          color: rgba(255, 255, 255, 0.9);
          font-size: 46px;
          font-weight: bold;
          line-height: 1.5;
          margin: 0px;
        }
      }

      &>div {
        height: 132px;
        padding: 20px 40px;

        strong {
          font-size: 30px;
          color: #121212;
          .f-limitMore(1);
        }

        p {
          color: #a8a9b2;
          font-size: 20px;
          margin-top: 10px;
          font-family: 'Poppins-Light';
          .f-limitMore(2);
        }

        a {
          color: #223efb;
        }

        &:hover {
          a {
            font-weight: bold;
          }

          strong {
            color: #223efb;
          }
        }
      }
    }

    .right {
      width: 45%;
      height: 100%;
      display: flex;
      flex-direction: column;

      // justify-content: space-between;
      &>li {
        background: #ffffff;
        border: 1px solid #c9cad6;
        color: #121212;
        font-size: 30px;
        padding: 30px 30px 30px 18px;

        p {
          display: inline-block;
        }

        &:not(:first-of-type) {
          margin-top: 20px;
        }

        img,
        .img-box {
          width: 80px;
          height: 80px;
          margin-right: 20px;
        }

        span {
          margin-top: 20px;
          font-size: 24px;
          color: #00ca98;
          display: block;
          text-align: center;
          display: none;
          .transition(0.3s all);
        }

        &:hover {
          background: #ebfffa;
          border: 1px solid #00ffc0;
          box-shadow: 0px 10px 28px 2px rgba(133, 160, 193, 0.15);
          cursor: pointer;

          span {
            display: block;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .m-index-title {
    padding: 80px * @media1024 0;
    font-size: 72px * @media1024;

    span {
      font-size: 24px * @media1024;
    }
  }

  .m-index-nodeIntroduction {
    height: 640px * @media1024;

    .nodeIntroduction-list {
      margin-bottom: 60px * @media1024;

      .item-li {
        width: 260px * @media1024;
        // height: 260px * @media1024;
        padding: 35px * @media1024 2px 35px * @media1024 2px;

        .u-img {
          height: 80px * @media1024;

          img,
          .img-box {
            width: 80px * @media1024;
            height: 80px * @media1024;
          }
        }

        .u-text {
          b {
            font-size: 36px * @media1024;

            span {
              font-size: 24px * @media1024;
            }
          }

          p {
            font-size: 16px * @media1024;
          }
        }

        &:hover {

          .u-img img,
          .img-box {
            margin-bottom: 30px * @media1024;
          }
        }
      }
    }
  }

  .m-index-node {
    .node-btn {
      margin-top: 60px * @media1024;

      .u-btn {
        width: 400px * @media1024;
        height: 100px * @media1024;
        font-size: 36px * @media1024;
        line-height: 100px * @media1024;

        &::after {
          width: 32px * @media1024;
          height: 22px * @media1024;
          margin-left: 15px * @media1024;
        }
      }
    }
  }

  .m-index-advantages {
    &>.animateBottom {

      img,
      .img-box {
        height: 190px * @media1024;
      }
    }

    .advantages-list {
      .adm-swiper {
        --height: 362px;
        padding-bottom: 0px;

        .adm-swiper-item {
          padding: 0px;

          .advantages-item {
            width: auto;
            height: 360px;
            align-items: center;
          }
        }
      }
    }
  }

  .m-index-resources {
    margin-top: 40px;

    .resource-list {
      width: 960px * @media1024;
      padding-bottom: 30px * @media1024;
    }

    .m-index-title {
      span {
        font-size: 72px * @media1024;
      }
    }
  }

  .m-index-content {
    .content-list {
      height: 550px * @media1024;

      .left {

        .img,
        .img-box {
          width: 100%;
          height: 378px * @media1024;
          overflow: hidden;
        }

        .img-text {
          height: 378px * @media1024;
          padding-left: 60px * @media1024;

          p {
            font-size: 46px * @media1024;
            line-height: 1.5;
          }
        }

        &>div {
          height: 132px * @media1024;
          padding: 20px * @media1024 40px * @media1024;

          strong {
            font-size: 30px * @media1024;
          }

          p {
            font-size: 20px * @media1024;
            margin-top: 10px * @media1024;
          }

          a {
            color: #223efb;
          }
        }
      }

      .right {
        &>li {
          font-size: 30px * @media1024;
          padding: 30px * @media1024 30px * @media1024 30px * @media1024 18px * @media1024;

          &:not(:first-of-type) {
            margin-top: 20px * @media1024;
          }

          img,
          .img-box {
            width: 80px * @media1024;
            height: 80px * @media1024;
            margin-right: 20px * @media1024;
          }

          span {
            margin-top: 20px * @media1024;
            font-size: 24px * @media1024;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .m-index-title {
    padding: 80rem / @media768 0;
    font-size: 72rem / @media768;
    text-align: left;
  }

  .m-index-nodeIntroduction {
    background: url('../../static/images/index-nodeIntroc-bg-mobile.jpg') no-repeat;
    background-position: left top;
    background-size: contain;
    height: auto;
    position: relative;

    &>.animateMobile {
      display: block;
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;

      img,
      .img-box {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    &>.animate {
      display: none;
    }

    .nodeIntroduction-list {
      flex-wrap: wrap;
      justify-content: space-between;

      .item-li {
        width: 49%;
        // height: 300rem / @media768;
        padding: 35rem / @media768 2px 35rem / @media768 2px;
        margin-bottom: 30rem / @media768;

        .u-img {
          height: 108rem / @media768;
        }

        .u-text {
          b {
            font-size: 48rem / @media768;

            span {
              font-size: 30rem / @media768;
            }
          }

          p {
            font-size: 24rem / @media768;
          }
        }

        &:hover {

          .u-img img,
          .img-box {
            margin-bottom: 30rem / @media768;
          }
        }
      }
    }
  }

  .m-index-node {
    box-shadow: none;

    .node-btn {
      width: 100%;
      margin-top: 60rem / @media768;

      .u-btn {
        width: 400rem / @media768;
        height: 100rem / @media768;
        font-size: 36rem / @media768;
        line-height: 100rem / @media768;

        &::after {
          content: '';
          width: 32rem / @media768;
          height: 22rem / @media768;
          margin-left: 15rem / @media768;
        }
      }
    }
  }

  .m-index-content {
    position: relative;
    background: none;

    &>.animateMobile {
      display: block;
      width: 280rem / @media768;
      position: absolute;
      top: 0px;
      right: 0px;

      img,
      .img-box {
        width: 280rem / @media768;
        height: 280rem / @media768;
        object-fit: contain;
      }
    }

    .content-list {
      height: auto;

      .left {
        display: none;
      }

      .right {
        width: 100%;
        height: auto;

        &>li {
          font-size: 30rem / @media768;
          padding: 18rem / @media768 30rem / @media768 18rem / @media768 18rem / @media768;

          &:not(:first-of-type) {
            margin-top: 20rem / @media768;
          }

          img,
          .img-box {
            width: 80rem / @media768;
            height: 80rem / @media768;
            margin-right: 20rem / @media768;
          }

          span {
            margin-top: 20rem / @media768;
            font-size: 24rem / @media768;
          }
        }
      }
    }
  }

  .m-index-advantages {
    &>.animate {
      display: none;
    }

    &>.animateBottom {
      display: none;
    }

    &>.animateMobile {
      display: block;
      width: 100%;
      position: absolute;
      top: 200rem / @media768;
      right: 0px;

      img,
      .img-box {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    .advantages-list {
      display: block;

      .adm-tabs {
        --fixed-active-line-width: 7.5rem;
        --active-line-color: linear-gradient(90deg, #11ffca 0%, #0072fe 100%);
      }

      .adm-swiper {
        --height: auto;
        padding-bottom: 0px;

        .advantages-item {
          width: 650rem / @media768;
          height: 450rem / @media768;

          img,
          .img-box {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  .m-index-resources {
    margin-top: 0px;
    position: relative;

    &>.animate {
      display: none;
    }

    .m-index-title {
      span {
        display: block;
        font-size: 72rem / @media768;
      }
    }

    .resource-list {
      width: 100%;
      padding-bottom: 30rem / @media768;

      li {
        width: 100%;
      }
    }
  }
}