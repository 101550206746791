@import '../../styles/variables.less';

.node-map {
	display: flex;
	margin-top: 50px;

	.u-map-list {
		position: relative;
		flex: 0 0 290px;
		// min-height: 620px;
		padding: 13px 0 15px 20px;
		background-color: #fff;
		height: 660px;
		overflow: hidden;

		&:hover {
			overflow: auto;
		}

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-track {
			background: #eee;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb {
			background: #cebac4;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: #cebac4;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb:active {
			background: #cebac4;
			border-radius: 10px;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 6px;
			background: url('../../static/images/index-node-mapList-bg.jpg') no-repeat center;
			background-size: 100%;
		}

		dl {
			position: relative;

			dt {
				height: 64px;
				font-size: 24px;
				font-weight: bold;
				line-height: 64px;
			}

			dd {
				height: 36px;
				font-weight: bold;
				line-height: 36px;
				cursor: pointer;

				&.none {
					display: none;
				}

				img,
				.img-box {
					display: inline-block;
					vertical-align: middle;
					width: 32px;
					height: 24px;
					margin-right: 10px;
					box-shadow: 0px 4px 6px 0px rgba(0, 25, 150, 0.1);
				}

				.flag {
					width: 32px;
					height: 24px;
					margin-right: 10px;
					box-shadow: 0px 4px 6px 0px rgba(0, 25, 150, 0.1);
				}

				.mapspeed {
					width: 20px;
					height: 20px;
					float: right;
					margin-top: 9px;

					&:hover {
						transform: scale(1.2);
					}

					display: none;
				}

				&>span {
					position: relative;
					display: inline-block;
					vertical-align: middle;
					width: 66%;

					&>span {
						display: inline-block;
						position: relative;
					}

					&>span::before {
						content: '';
						position: absolute;
						bottom: 0;
						left: 0;
						width: 0;
						height: 2px;
						background-color: @c-mainColor;
						.transition(0.3s all);
					}
				}

				&:hover,
				&.active {
					&>.locale-name>span::before {
						width: 100%;
					}
				}
			}

			.bottom {
				position: absolute;
				bottom: 0px;
				width: 100%;
				text-align: center;
				background: rgba(255, 255, 255, 0.8);
			}
		}
	}

	.u-map-box {
		flex: auto;
		position: relative;

		.u-title {
			height: 92px;
			font-size: 24px;
			line-height: 92px;
			text-align: center;
		}

		.u-map {
			position: relative;
			height: 510px;
			margin: 18px 18px 0 82px;
			background: url(../../static/images/index-node-map-bg.png) no-repeat center;
			background-size: 100% 100%;

			&>div {
				position: absolute;
				z-index: 2;
				opacity: 1;

				&::before {
					content: '';
					position: absolute;
					z-index: 999;
					top: 7px;
					right: 7px;
					width: 6px;
					height: 6px;
					background-color: @c-mainColor;
					border-radius: 50%;
				}

				span {
					background: url('../../static/images/index-node-map-spot.png') no-repeat center;
					background-size: 20px 20px;
					display: block;
					width: 20px;
					height: 20px;

					&.randomActive {
						animation: mapSpot 2s 1;
					}
				}

				div[class^='purple'] {
					position: absolute;
					width: 20px;
					height: 20px;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					border-radius: 50%;
					background: rgba(8, 123, 255, 0.6);
					opacity: 0;
				}

				.purple1 {
					animation: suo 1200ms linear;
				}

				label {
					position: absolute;
					top: 0;
					right: 22px;
					width: 0;
					overflow: hidden;
					// font-size: 12px;
					font-weight: bold;
					text-align: right;
					white-space: nowrap;
					opacity: 0;

					img,
					.img-box {
						display: inline-block;
						vertical-align: middle;
						width: 30px;
						height: 20px;
						margin-right: 5px;
					}
				}

				&:hover,
				&.active {
					label {
						width: auto;
						opacity: 1;
					}

					span {
						animation: mapSpot 1s infinite;
					}
				}
			}
		}

		.count {
			position: absolute;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: #007bfc;
			background: #fff;
			box-shadow: 0 0px 20px #eee;
			border-radius: 100%;
			overflow: hidden;
			width: 120px;
			height: 120px;
			border-radius: 50%;
			right: 10px;
			top: 20px;
			font-size: 44px;
			// &::after {
			//   display: inline-block;
			//   content: counter(count1);
			//   animation: counter1 500ms;
			//   counter-increment: count1 10;
			//   counter-reset: count1 4;
			// }
		}
	}

	&.node-map-other {
		.u-map-list {
			background-color: #fff;

			dl {
				dd {
					span {
						&::before {
							content: '';
							background-color: #7883b2;
						}
					}
				}
			}
		}

		.u-map-box {
			.u-map {
				div {
					&::before {
						content: '';
						background-color: #7883b2;
					}

					span {
						background: url('../../static/images/index-node-map-spot.png') no-repeat center;
						border-radius: 50%;
					}

					div[class^='purple'] {
						background: #7883b2;
					}
				}
			}

			.count {
				color: #7883b2;
				// border-radius: 50%;
				right: 25px;
				top: 35px;
				font-size: 34px;
				width: 90px;
				height: 90px;
				// &::after {
				//   display: inline-block;
				//   content: counter(count);
				//   animation: counter 500ms linear 1;
				//   counter-reset: count 3;
				// }
			}
		}
	}
}

.node-map-popover {
	.ant-popover-title {
		display: flex;
		justify-content: center;
		color: #000;
		font-size: 14px;
		font-weight: bold;
		padding: 16px 14px;
		min-width: 140px;

		img,
		.img-box {
			display: inline-block;
			vertical-align: middle;
			width: 32px;
			height: 24px;
			margin-right: 10px;
			box-shadow: 0px 4px 6px 0px rgba(0, 25, 150, 0.1);
		}

		.flag {
			width: 32px;
			height: 24px;
			margin-right: 10px;
			box-shadow: 0px 4px 6px 0px rgba(0, 25, 150, 0.1);
		}
	}

	.ant-popover-inner {
		border-radius: 10px;
	}

	.ant-popover-inner-content {
		text-align: center;
		color: @c-mainColor;
		font-size: 14px;
		padding: 10px 14px;

		&:hover {
			font-weight: bold;
			cursor: pointer;
		}
	}
}

.noContent {
	.ant-popover-inner-content {
		padding: 0px;
	}
}

@media screen and (max-width: 1440px) {
	.node-map {
		margin-top: 50px * @media1024;

		.u-map-list {
			flex: 0 0 290px * @media1024;
			// min-height: 620px * @media1024;
			padding: 13px * @media1024 0 15px * @media1024 20px * @media1024;
			height: 670px * @media1024;

			&::before {
				content: '';
				height: 6px * @media1024;
			}

			dl {
				dt {
					height: 64px * @media1024;
					font-size: 24px * @media1024;
					line-height: 64px * @media1024;
				}

				dd {
					font-size: 12px;
					height: 36px * @media1024;
					line-height: 36px * @media1024;

					.mapspeed {
						width: 20px * @media1024;
						height: 20px * @media1024;
						margin-top: 9px * @media1024;
					}

					img,
					.flag,
					.img-box {
						width: 32px * @media1024;
						height: 24px * @media1024;
						margin-right: 10px * @media1024;
					}
				}
			}
		}

		.u-map-box {
			.u-title {
				height: 92px * @media1024;
				font-size: 24px * @media1024;
				line-height: 92px * @media1024;
			}

			.u-map {
				height: 510px * @media1024;
				margin: 18px * @media1024 18px * @media1024 0 82px * @media1024;

				&>div {
					.purple1 {
						animation: suo 1200ms linear;
					}

					&::before {
						z-index: 999;
						top: 7px * @media1024;
						right: 7px * @media1024;
						width: 6px * @media1024;
						height: 6px * @media1024;
						background-color: @c-mainColor;
					}

					span {
						background-size: 20px * @media1024 20px * @media1024;
						width: 20px * @media1024;
						height: 20px * @media1024;
					}

					div[class^='purple'] {
						width: 20px * @media1024;
						height: 20px * @media1024;
					}

					label {
						right: 22px * @media1024;

						img,
						.img-box {
							width: 30px * @media1024;
							height: 20px * @media1024;
							margin-right: 5px * @media1024;
						}
					}
				}
			}

			.count {
				width: 120px * @media1024;
				height: 120px * @media1024;
				border-radius: 50%;
				right: 10px * @media1024;
				top: 20px * @media1024;
				font-size: 44px * @media1024;
			}
		}

		&.node-map-other {
			.u-map-box {
				.count {
					right: 25px * @media1024;
					top: 35px * @media1024;
					font-size: 34px * @media1024;
					width: 90px * @media1024;
					height: 90px * @media1024;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.node-map {
		display: flex;
		margin-top: 60rem / @media768;

		.u-map-list {
			flex: 1;
			height: auto;
			padding: 4% 2%;

			&:hover {
				overflow: hidden;
			}

			dl {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				position: relative;
				margin-top: 40rem / @media768;

				dt {
					width: 100%;
					margin: 40rem / @media768 0;
					font-size: 36rem / @media768;
					height: 64rem / @media768;
					line-height: 64rem / @media768;
				}

				dd {
					flex: 46% 0 0;
					margin-bottom: 26rem / @media768;
					height: 36rem / @media768;
					line-height: 36rem / @media768;

					&:nth-of-type(odd) {
						padding-right: 20rem / @media768;
					}

					&:nth-of-type(even) {
						padding-left: 20rem / @media768;
					}

					span {
						font-size: 24rem / @media768;
					}

					img,
					.img-box {
						display: inline-block;
						vertical-align: middle;
						width: 32rem / @media768;
						height: 24rem / @media768;
						margin-right: 10rem / @media768;
					}

					.flag {
						width: 32rem / @media768;
						height: 24rem / @media768;
						margin-right: 10rem / @media768;
					}

					.mapspeed {
						width: 30rem / @media768;
						height: 30rem / @media768;
						margin-top: 3rem / @media768;
						margin-right: 0;
						display: block;
						box-shadow: none;

						&:hover {
							transform: scale(1.2);
						}
					}

					&:hover,
					&.active {
						.locale-name>span::before {
							width: 100%;
						}
					}
				}

				&::after {
					content: '';
					width: 2px;
					height: 96%;
					background: #999;
					display: block;
					position: absolute;
					top: 0px;
					left: 50%;
				}
			}
		}

		.u-map-box {
			display: none;
		}
	}

	.node-map-other {
		.u-map-list {
			dl {
				&::after {
					content: '';
					width: 0px;
					height: 0px;
				}
			}
		}
	}
}

//begin map动画
@keyframes mapSpot {
	from {
		.transform(scale(1));
	}

	to {
		.transform(scale(3));
	}
}

@scale: 1;

@keyframes suo {
	0% {}

	30% {
		width: 30px * @scale;
		height: 30px * @scale;
		opacity: 0.5;
	}

	60% {
		width: 40px * @scale;
		height: 40px * @scale;
		opacity: 0.6;
	}

	70% {
		width: 50px * @scale;
		height: 50px * @scale;
		opacity: 0.4;
	}

	80% {
		width: 56px * @scale;
		height: 56px * @scale;
		opacity: 0.2;
	}

	100% {
		width: 60px * @scale;
		height: 60px * @scale;
		opacity: 0;
	}
}